.panel {
  margin: 18px;
  background-color: #f4f4f4;
  padding: 24px 24px;
  min-height: 320px;
  transition: all 500ms ease-in-out;
  border-radius: 8px;
}

@media (min-width: 760px) {
  .panel {
    margin: 16px 24px 96px;
  }
}
