.h1Text {
  font-size: 32px;
}

.h2Text {
  font-size: 24px;
}

.h3Text {
  font-size: 20px;
}

.smallText {
  font-size: 11px;
  color: #555;
}

.bodyText {
  line-height: 1.4;
}

.strong {
  font-weight: bold;
}

.bodyTextEmphasize {
  composes: bodyText strong;
}

.h3,
h3.h3 {
  font-size: 20px;
  margin: 12px 0;
}

.h4,
h4.h4 {
  font-size: 16px;
  margin: 12px 0;
}

.body,
p.body {
  line-height: 1.4;
  font-size: 14px;
  margin: 8px 0;
}
