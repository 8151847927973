@value colorDarkGrayText from '../../styles/colors.module.css';

.leadInputContainer {
  margin: 1rem 0;
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.submitContainer {
  composes: leadInputContainer;
  justify-content: flex-end;
}

@media(min-width: 760px) {
  .submitContainer {
    margin-top: -1rem;
  }
}

.leadInputContainer .label {
  display: inline-block;
  flex: 0 0 168px;
  padding: 4px 0px;
}

.loginLink {
  font-weight: normal;
  font-size: 0.9rem;
  color: colorDarkGrayText;
}

/* This one is a different width because of label weirdness */
/* @media (min-width: 1165px) {
  .leadInputContainer .label {
    text-align: right;
  }
} */

.leadInput {
  margin-left: 0;
  font-size: 1.3rem;
  padding: 0.3rem !important;
  border: 1px solid #eee;
  height: auto;
  min-width: 0;
  width: 100%;
}

@media (min-width: 760px) {
  .leadInput {
    /* margin-right: 16px; */
    flex: 1 1 auto;
    width: auto;
  }
  .leadInputContainer .label {
    margin-right: 1rem;
  }
}

.leadInputPhoneContainer {
  min-width: 0;
  width: 100%;
}

@media (min-width: 760px) {
  .leadInputPhoneContainer {
    flex: 1 1 auto;
    width: auto;
    margin-right: 12px;
  }
}

.leadInputPhone {
  composes: leadInput;
}

@media (min-width: 760px) {
  .leadInputPhone {
    margin-right: 12px; /* Need this to push switch button right */
  }
}

.leadInputEmail {
  composes: leadInput;
  width: 100%;
}

.switchButton {
  /* margin: 0; */
  margin: 18px 0;
  cursor: pointer;
  text-align: right;
  flex: 0 0 100%;
}

@media (min-width: 760px) {
  .switchButton {
    flex: 1 1 auto;
    text-align: left;
    margin-right: 12px;
  }
}

/* @media (min-width: 1156px) {
  .switchButton {
    padding-left: 174px;
    margin: 16px 0 4px;
    flex: 0 0 100%;
  }
} */

/* @media (min-width: 1450px) {
  .switchButton {
    padding-left: 12px;
  }
} */

.switchUnderline {
  text-decoration: underline;
}

/* TODO: Break this out into it's own thang */
.errors {
  flex: 0 0 100%;
  font-size: 14px;
}

.error {
  display: block;
  color: red;
}

.form {
  max-width: 800px;
}

.stayLoggedIn {
  display: flex;
  justify-content: flex-end;
  /* width: 100%; */
  margin-left: auto;
}

/* @media (min-width: 1310px) {
  .stayLoggedIn {
    margin-left: 16px;
    padding-left: 168px;
    text-align: left;
  }
}

@media (min-width: 1350px) {
  .stayLoggedIn {
    text-align: left;
  }
} */

.form .stayLoggedIn span {
  font-size: 1rem !important;
}

.form .stayLoggedIn label {
  margin-right: 0;
}
